import { ReactSVG } from 'react-svg'
import { useTranslation } from 'next-i18next'

import { ILoginFormTypes } from '@interfaces/global.type'

import Button from '@components/UI/Button/Button'

import styles from './PwReseted.module.sass'

interface IProps {
  setFormType: (formType: ILoginFormTypes) => void
}

const PwReseted = ({ setFormType }: IProps) => {
  const { t } = useTranslation('login')

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.headerContainer}>
          <h3>{t('pwResetedHeader')}</h3>
        </div>

        <div className={styles.btnsContainer}>
          <div className={styles.tickIconContainer}>
            <ReactSVG src='/icons/tick.svg' />
          </div>
          <Button className='newInverted' onClick={() => setFormType('login')}>
            {t('pwResetedBtn')}
          </Button>
          <Button href='/' className='newInverted'>
            {t('goToMainPage')}
          </Button>
        </div>

        <div className={styles.mapMarkBlueContainer}>
          <ReactSVG src='/icons/map-mark-blue.svg' />
        </div>

        <div className={styles.mapMarkYellowContainer}>
          <ReactSVG src='/icons/map-mark-yellow.svg' />
        </div>
      </div>
    </div>
  )
}

export default PwReseted
