import { useState, useEffect } from 'react'
import { useTranslation } from 'next-i18next'

import { REQ } from '@api/index'
import { useDebounce } from '@hooks/useDebounce'
import { handleBackErrors } from '@utils/handleBackErrors'
import {
  getValidationErrorMessage,
  isValidationErrorsEmpty,
} from '@utils/validation'
import { registerFormDto } from './interfaces'
import { makeValidation } from './formValidations'

import SmallHeader from '@components/UI/SmallHeader/SmallHeader'
import Input from '@components/UI/Input/Input'
import Button from '@components/UI/Button/Button'

import styles from './form.module.sass'
import { ACCESS_TOKEN, REFRESH_TOKEN, USER_INFO } from 'const/token'
import ModalSuccess from '@components/UI/components/ModalSuccess/ModalSuccess'
import { CustomPhoneInput } from '@components/UI/Input/CustomPhoneInput'

const RegisterForm = () => {
  const { t } = useTranslation('login')

  const { register } = REQ.auth_api

  const [formValues, setFormValues] = useState<registerFormDto>({
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    phoneNumber: '',
    password: '',
    passwordConfirm: '',
    birthDate: '',
  })
  const [isSuccessRegister, setIsSuccessRegister] = useState(false)

  const [validationErrors, setSetValidationErrors] = useState({})

  const debauncedValue = useDebounce(formValues, 1000)

  const {
    firstName,
    lastName,
    middleName,
    email,
    phoneNumber,
    password,
    passwordConfirm,
    birthDate,
  } = formValues

  const submitRegisterHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    register(formValues)
      .then(() => {
        setIsSuccessRegister(true)
        setFormValues({
          firstName: '',
          lastName: '',
          middleName: '',
          email: '',
          phoneNumber: '',
          password: '',
          passwordConfirm: '',
          birthDate: '',
        })
      })
      .catch((err) => {
        console.log(err)
        handleBackErrors(err)
      })
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  const handleChangePhoneInput = (phone: string) => {
    setFormValues({ ...formValues, phoneNumber: phone })
  }

  const formValidation = (formValues: any) => {
    const result = makeValidation(formValues, 'registerForm')

    if (result.status === 'error') {
      setSetValidationErrors(result.errors)
    } else {
      setSetValidationErrors({})
    }
  }

  useEffect(() => {
    formValidation(formValues)
  }, [debauncedValue])

  return (
    <div className={styles.container}>
      {isSuccessRegister ? (
        <ModalSuccess
          type='register'
          isOpen={isSuccessRegister}
          closeHandler={() => setIsSuccessRegister(!isSuccessRegister)}
          title='Ваш обліковий запис успішно створено'
          additionalText='Для підтвердження облікового запису перейдіть на пошту та перейдіть за посиланням'
          links={[]}
        />
      ) : null}
      <form onSubmit={submitRegisterHandler}>
        <SmallHeader>{t('registerSubHeader')}</SmallHeader>
        <Input
          required
          placeholder={t('namePlaceholder')}
          value={firstName}
          onChange={handleChangeInput}
          name='firstName'
          type='text'
          isValid={!validationErrors.hasOwnProperty('firstName')}
          errorMessage={getValidationErrorMessage(
            validationErrors,
            'firstName',
          )}
        />
        <Input
          required
          placeholder={t('secondNamePlaceholder')}
          value={lastName}
          onChange={handleChangeInput}
          name='lastName'
          type='text'
          isValid={!validationErrors.hasOwnProperty('lastName')}
          errorMessage={getValidationErrorMessage(validationErrors, 'lastName')}
        />
        <Input
          placeholder={t('middleNamePlaceholder')}
          value={middleName}
          onChange={handleChangeInput}
          name='middleName'
          type='text'
          isValid={!validationErrors.hasOwnProperty('middleName')}
          errorMessage={getValidationErrorMessage(
            validationErrors,
            'middleName',
          )}
        />
        <Input
          required
          placeholder={t('emailPlaceholder')}
          value={email}
          onChange={handleChangeInput}
          name='email'
          type='email'
          isValid={!validationErrors.hasOwnProperty('email')}
          errorMessage={getValidationErrorMessage(validationErrors, 'email')}
        />
        <CustomPhoneInput
          value={phoneNumber}
          placeholder='Номер телефону'
          handleChange={handleChangePhoneInput}
        />

        <Input
          required
          placeholder={t('birthDatePlaceholder')}
          value={birthDate}
          onChange={handleChangeInput}
          name='birthDate'
          type='date'
          isValid={!validationErrors.hasOwnProperty('birthDate')}
          errorMessage={getValidationErrorMessage(
            validationErrors,
            'birthDate',
          )}
        />
        <Input
          required
          placeholder={t('passwordPlaceholder')}
          value={password}
          onChange={handleChangeInput}
          name='password'
          type='password'
          isValid={!validationErrors.hasOwnProperty('password')}
          errorMessage={getValidationErrorMessage(validationErrors, 'password')}
        />
        <Input
          required
          placeholder={t('passwordConfirmPlaceholder')}
          value={passwordConfirm}
          onChange={handleChangeInput}
          name='passwordConfirm'
          type='password'
          isValid={
            password === passwordConfirm &&
            !validationErrors.hasOwnProperty('passwordConfirm')
          }
          errorMessage={
            password !== passwordConfirm
              ? `${t('passwordsDoNotMatch')}`
              : getValidationErrorMessage(validationErrors, 'passwordConfirm')
          }
        />
        <Button
          className='newInverted'
          disabled={
            (firstName !== '' &&
              lastName !== '' &&
              email !== '' &&
              phoneNumber !== '' &&
              password !== '' &&
              passwordConfirm !== '' &&
              !isValidationErrorsEmpty(validationErrors)) ||
            password !== passwordConfirm
          }
        >
          {t('registerBtn')}
        </Button>
      </form>
    </div>
  )
}

export default RegisterForm
