import { useState, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { toast } from 'react-toastify'

import { REQ } from '@api/index'
import { useDebounce } from '@hooks/useDebounce'
import { handleBackErrors } from '@utils/handleBackErrors'
import {
  getValidationErrorMessage,
  isValidationErrorsEmpty,
} from '@utils/validation'
import { forgotPwFormDto } from './interfaces'
import { makeValidation } from './formValidations'

import Input from '@components/UI/Input/Input'
import Button from '@components/UI/Button/Button'

import styles from './form.module.sass'

const ForgotPwForm = () => {
  const { t } = useTranslation('login')
  const { push } = useRouter()

  const { recoverPassword } = REQ.users_api

  const [formValues, setFormValues] = useState<forgotPwFormDto>({
    email: '',
  })
  const [validationErrors, setSetValidationErrors] = useState({})

  const debauncedValue = useDebounce(formValues, 1000)

  const { email } = formValues

  const submitForgotPwHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    recoverPassword(formValues.email)
      .then((res) => {
        toast.success(t('accountHasBeenFound'))
        const token = res.data.split('rtk=')[1]
        push(`/recovery-password?rtk=${token}`)
      })
      .catch((err) => {
        console.log(err)

        handleBackErrors(err)
      })
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  const formValidation = (formValues: any) => {
    const result = makeValidation(formValues, 'forgotPwForm')

    if (result.status === 'error') {
      setSetValidationErrors(result.errors)
    } else {
      setSetValidationErrors({})
    }
  }

  useEffect(() => {
    formValidation(formValues)
  }, [debauncedValue])

  return (
    <div className={styles.container}>
      <form onSubmit={submitForgotPwHandler}>
        <Input
          required
          placeholder={t('emailPlaceholder')}
          value={email}
          onChange={handleChangeInput}
          name='email'
          type='email'
          isValid={!validationErrors.hasOwnProperty('email')}
          errorMessage={getValidationErrorMessage(validationErrors, 'email')}
        />

        <Button
          className='newInverted'
          disabled={email !== '' && !isValidationErrorsEmpty(validationErrors)}
        >
          {t('submitEmailToRestorePw')}
        </Button>
      </form>
    </div>
  )
}

export default ForgotPwForm
