import { useTranslation } from 'next-i18next'

import { ILoginFormTypes } from '@interfaces/global.type'

import LoginForm from '@components/forms/LoginForm'
import RegisterForm from '@components/forms/RegisterForm'

import styles from './LoginSwitcher.module.sass'

interface IProps {
  formType: ILoginFormTypes
  setFormType: (formType: ILoginFormTypes) => void
}

const LoginSwitcher = ({ formType, setFormType }: IProps) => {
  const { t } = useTranslation('login')

  return (
    <div className={styles.container}>
      <div className={styles.switchersBtnsContainer}>
        <button
          type="button"
          onClick={() => setFormType('login')}
          className={
            formType === 'login' ? styles.switcherBtnActive : styles.switcherBtn
          }>
          {t('login')}
        </button>

        <button
          type="button"
          onClick={() => setFormType('register')}
          className={
            formType === 'register'
              ? styles.switcherBtnActive
              : styles.switcherBtn
          }>
          {t('register')}
        </button>
      </div>

      <div className={styles.formContainer}>
        {formType === 'login' && <LoginForm setFormType={setFormType} />}
        {formType === 'register' && <RegisterForm />}
      </div>
    </div>
  )
}

export default LoginSwitcher
