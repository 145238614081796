import React from 'react'
import { ReactSVG } from 'react-svg'

import { IChildren } from '@interfaces/global.type'

import styles from './Modal.module.sass'
import { divIcon } from 'leaflet'

interface IProps {
  children: IChildren
  closeHandler: () => void
  isOpen: boolean
  title?: string | JSX.Element
  style?: React.CSSProperties
  styleContainer?: React.CSSProperties
  accentColor?: 'blue' | 'yellow',
  hasCloseButton?: boolean
}

const Modal = ({
  children,
  closeHandler,
  isOpen,
  title,
  style,
  styleContainer,
  accentColor,
  hasCloseButton = true
}: IProps) => {
  const MODAL_ID = Math.random().toString(36).substring(7)

  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()

    const el = e.target as HTMLInputElement
    if (el.getAttribute('data-name') === MODAL_ID) {
      closeHandler()
    }
  }

  const defaultStyle = {
    yellow: styles.yellow,
    blue: styles.blue,
  }

  return (
    isOpen && (
      <div
        className={styles.container}
        onClick={handleClose}
        data-name={MODAL_ID}
        style={{
          opacity: isOpen ? 1 : 0,
          ...styleContainer,
        }}
      >
        <div className={styles.contentContainer} style={style}>
          {title && typeof title === 'string' && (
            <h1 className={styles.title}>{title}</h1>
          )}
          {title && typeof title !== 'string' && (
            <div
              className={`${styles.titleContainer} ${defaultStyle[accentColor]}`}
            >
              {title}
            </div>
          )}
          {
            hasCloseButton
              && (<div className={styles.closeIconContainer} onClick={closeHandler}>
              <ReactSVG src='/icons/close.svg' />
            </div>)
          }

          <div className={styles.childrenContainer}>{children}</div>
        </div>
      </div>
    )
  )
}

export default Modal
