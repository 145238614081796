import { useState } from 'react'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'

import { ILoginFormTypes } from '@interfaces/global.type'

import PageWrapper from '@components/wrappers/PageWrapper'
import Header from '@components/UI/Header/Header'
import LoginSwitcher from '@components/componentsLogin/LoginSwitcher/LoginSwitcher'
import ForgotPw from '@components/componentsLogin/ForgotPw/ForgotPw'
import PwReseted from '@components/componentsLogin/PwReseted/PwReseted'

import styles from '@styles/pagesStyles/login.module.sass'

const initialStatus: ILoginFormTypes = 'login'

const login = () => {
  const { t } = useTranslation('login')
  const [formType, setFormType] = useState<ILoginFormTypes>(initialStatus)

  return (
    <PageWrapper>
      {formType === 'reseted' ? (
        <PwReseted setFormType={setFormType} />
      ) : (
        <div className={styles.container}>
          <div className={styles.contentContainer}>
            <div className={styles.leftContainer}>
              <div className={styles.headerContainer}>
                <Header tag='h1' color='yellow'>
                  {formType === 'login' && (
                    <>
                      <span>{t('loginHeader1')}</span>
                      {t('loginHeader2')}
                    </>
                  )}
                  {formType === 'register' && (
                    <>
                      <span>{t('registerHeader1')}</span>
                      {t('registerHeader2')}
                    </>
                  )}
                  {formType === 'forgot' && (
                    <>
                      <span>{t('forgotHeader1')}</span>
                      {t('forgotHeader2')}
                    </>
                  )}
                </Header>
              </div>

              <div className={styles.imgContainer}>
                <Image
                  src='/images/login.webp'
                  alt='login perevozka'
                  layout='fill'
                />
              </div>
            </div>

            <div className={styles.loginSwitcherContainer}>
              <div className={styles.imgContainerTablet}>
                <Image
                  src='/images/login.webp'
                  alt='login perevozka'
                  layout='fill'
                />
              </div>
              {(formType === 'login' || formType === 'register') && (
                <LoginSwitcher formType={formType} setFormType={setFormType} />
              )}

              {formType === 'forgot' && <ForgotPw setFormType={setFormType} />}
            </div>
          </div>
        </div>
      )}
    </PageWrapper>
  )
}

export default login

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['login', 'common'])),
    },
  }
}
