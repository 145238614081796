import React from 'react'
import PhoneInput from 'react-phone-input-2'
import { availableCountries } from 'const/countries'
import styles from './Input.module.sass'
import 'react-phone-input-2/lib/style.css'

interface IPhoneInputProps {
  value: string
  placeholder?: string
  country?: string
  handleChange: (value: string) => void
  countriesList?: string[]
}

export const CustomPhoneInput = ({
  value,
  placeholder,
  country = 'ua',
  handleChange,
  countriesList = availableCountries,
}: IPhoneInputProps) => {
  return (
    <PhoneInput
      value={value}
      placeholder={placeholder}
      inputClass={styles.input}
      onlyCountries={countriesList}
      dropdownStyle={{
        maxHeight: '90px',
      }}
      country={country}
      inputStyle={{
        marginBottom: '10px',
      }}
      enableClickOutside
      onChange={handleChange}
    />
  )
}
