// import { ReactSVG } from 'react-svg'
import { useTranslation } from 'next-i18next'

import { ILoginFormTypes } from '@interfaces/global.type'
import { useAppStore } from '@store/useAppStore'

import SmallHeader from '@components/UI/SmallHeader/SmallHeader'
import ForgotPwForm from '@components/forms/ForgotPwForm'
import NewPwForm from '@components/forms/NewPwForm'
// import Input from '@components/UI/Input/Input'
// import Button from '@components/UI/Button/Button'

import styles from './ForgotPw.module.sass'

interface IProps {
  setFormType: (formType: ILoginFormTypes) => void
}

const ForgotPw = ({ setFormType }: IProps) => {
  const { t } = useTranslation('login')
  const { rtkStatus, setRtkStatus } = useAppStore()

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <SmallHeader>{t('forgotSubHeader')}</SmallHeader>
      </div>

      {rtkStatus === 'rtkStatusFailed' && (
        <p className={styles.rtkStatusError}>{t('rtkErrorMessage')}</p>
      )}

      {(rtkStatus === 'getRtkStatus' || rtkStatus === 'rtkStatusFailed') && (
        <div className={styles.formContainerTop}>
          <ForgotPwForm />
        </div>
      )}

      {/* <div className={styles.codeStatusContainer}>
        <div className={styles.iconContainer}>
          <ReactSVG src="/icons/tick.svg" />
        </div>
        <p>{t('codeStatus')}</p>
      </div>

      <div className={styles.confirmFormContainer}>
        <form>
          <div className={styles.inputContainer}>
            <Input
              type="text"
              placeholder={t('codePlaceholder')}
              value={''}
              isValid={true}
              onChange={() => {}}
            />
          </div>

          <div className={styles.btnContainer}>
            <Button className="thin">{t('confirmBtn')}</Button>
          </div>
        </form>
      </div> */}

      {rtkStatus === 'rtkStatusSuccess' && (
        <div className={styles.formContainer}>
          <NewPwForm setFormType={setFormType} />
        </div>
      )}

      <div
        className={styles.goToLogin}
        onClick={() => {
          setRtkStatus('getRtkStatus')
          setFormType('login')
        }}>
        <span>{t('goToLogin')}</span>
      </div>
    </div>
  )
}

export default ForgotPw
