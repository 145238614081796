import { toast } from 'react-toastify'

export const handleBackErrors = (err: any) => {
  if (
    err.response.data.message &&
    typeof err.response.data.message === 'string'
  ) {
    toast.error(err.response.data.message)
  } else if (
    err.response.data.message &&
    err.response.data.message?.length > 0
  ) {
    toast.error(err.response.data.message[0])
  } else {
    toast.error('Something went wrong')
  }
}
