import { useState, useEffect } from 'react'

import { ILoginFormTypes } from '@interfaces/global.type'
import { useTranslation } from 'next-i18next'
import { useAppStore } from '@store/useAppStore'

import { toast } from 'react-toastify'

import { REQ } from '@api/index'
import { useDebounce } from '@hooks/useDebounce'
import { handleBackErrors } from '@utils/handleBackErrors'
import {
  getValidationErrorMessage,
  isValidationErrorsEmpty,
} from '@utils/validation'
import { newPwFormDto } from './interfaces'
import { makeValidation } from './formValidations'

import Input from '@components/UI/Input/Input'
import Button from '@components/UI/Button/Button'

import styles from './form.module.sass'

interface IProps {
  setFormType: (formType: ILoginFormTypes) => void
}

const NewPwForm = ({ setFormType }: IProps) => {
  const { t } = useTranslation('login')
  const { setRtkStatus, rtk } = useAppStore()

  const [formValues, setFormValues] = useState<newPwFormDto>({
    password: '',
    passwordConfirm: '',
  })
  const [validationErrors, setSetValidationErrors] = useState({})

  const debauncedValue = useDebounce(formValues, 1000)

  const { password, passwordConfirm } = formValues

  const submitFormHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    REQ.users_api
      .changePassword(rtk, formValues.password)
      .then(() => {
        toast.success('Password has been changed')
        setRtkStatus('getRtkStatus')
        setFormType('reseted')
      })
      .catch((err) => {
        console.log(err)
        handleBackErrors(err)
      })
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  const formValidation = (formValues: any) => {
    const result = makeValidation(formValues, 'resetPwForm')

    if (result.status === 'error') {
      setSetValidationErrors(result.errors)
    } else {
      setSetValidationErrors({})
    }
  }

  useEffect(() => {
    formValidation(formValues)
  }, [debauncedValue])

  return (
    <div className={styles.container}>
      <form onSubmit={submitFormHandler}>
        <Input
          required
          placeholder={t('newPwPlaceholder')}
          value={password}
          onChange={handleChangeInput}
          name='password'
          type='password'
          isValid={!validationErrors.hasOwnProperty('password')}
          errorMessage={getValidationErrorMessage(validationErrors, 'password')}
        />

        <Input
          required
          placeholder={t('confirmPwPlaceholder')}
          value={passwordConfirm}
          onChange={handleChangeInput}
          name='passwordConfirm'
          type='password'
          isValid={
            password === passwordConfirm &&
            !validationErrors.hasOwnProperty('passwordConfirm')
          }
          errorMessage={
            password !== passwordConfirm && `${t('passwordsDoNotMatch')}`
          }
        />

        <Button
          className='newInverted'
          disabled={
            (password !== '' &&
              passwordConfirm !== '' &&
              !isValidationErrorsEmpty(validationErrors)) ||
            password !== passwordConfirm
          }
        >
          {t('submitPwBtn')}
        </Button>
      </form>
    </div>
  )
}

export default NewPwForm
