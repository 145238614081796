import React from 'react'
import Image from 'next/image'
import { ReactSVG } from 'react-svg'

import Modal from '@components/UI/Modal/Modal'

import Button from '@components/UI/Button/Button'
import Header from '@components/UI/Header/Header'

import styles from './ModalSuccess.module.sass'
import { hr } from 'date-fns/locale'

interface ILink {
  href?: string
  iconUrl: string
  text: string
}

interface IProps {
  isOpen: boolean
  closeHandler: () => void
  title?: string
  type: 'delivery' | 'register' | 'travel'
  additionalText?: string
  contactText?: string
  accentText?: string
  imgUrl?: string
  links?: ILink[]
  dispatcherLink?: string
  cabinetLink?: string
}

const ModalSuccess = ({
  type,
  isOpen,
  closeHandler,
  title = 'Ваше бронювання очікує підтвердження',
  additionalText,
  contactText,
  accentText = '',
  imgUrl = '/images/bell.png',
  links = [
    {
      href: 'https://t.me/perevozka_travel',
      iconUrl: '/icons/telegram-success-modal.svg',
      text: '+380 50 820 48 83',
    },
    {
      // href: '',
      iconUrl: '/icons/viber-success-modal.svg',
      text: '+380 50 820 48 83',
    },
  ],
  dispatcherLink = '',
  cabinetLink = '',
}: IProps) => {
  return (
    <Modal isOpen={isOpen} closeHandler={closeHandler}>
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <div className={styles.contentContainer}>
            <div className={styles.headerContainer}>
              <Header>{title}</Header>
            </div>

            <div className={styles.imgMobContainer}>
              <div className={styles.elipsBlur}></div>
              <Image src={imgUrl} alt='bell' layout='fill' />
            </div>

            {additionalText && <p>{additionalText}</p>}

            <div className={styles.accentTextContainer}>
              {accentText && <p>{accentText}</p>}
              {cabinetLink && (
                <div className={styles.btnContainer}>
                  <a href='/cabinet' target='_blank' rel='noreferrer'>
                    <Button className='newInverted'>Перейти в кабінет</Button>
                  </a>
                </div>
              )}
              {contactText && <p>{contactText}</p>}
              {links.length > 0 && (
                <div className={styles.linksContainer}>
                  {links.map((link) => (
                    <div className={styles.linkItemContainer}>
                      {link.href ? (
                        <>
                          {type === 'delivery' || type === 'travel' ? (
                            <Button
                              href={link.href}
                              target='_blank'
                              className={styles.contactBtn}
                            >
                              <ReactSVG
                                src={link.iconUrl}
                                className={styles.icon}
                              />
                              <span>{link.text}</span>
                            </Button>
                          ) : (
                            <a href={link.href}>
                              <div className={styles.iconContainer}>
                                <ReactSVG src={link.iconUrl} />
                              </div>
                              <p>{link.text}</p>
                            </a>
                          )}
                        </>
                      ) : (
                        <div className={styles.linkTextItemContainer}>
                          <div className={styles.iconContainer}>
                            <ReactSVG src={link.iconUrl} />
                          </div>
                          <p>{link.text}</p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className={styles.imgContainer}>
            <div className={styles.elipsBlur}></div>
            <Image src={imgUrl} alt='bell' layout='fill' />
          </div>
        </div>

        <div className={styles.btnsContainer}>
          {dispatcherLink && (
            <div className={styles.btnContainer}>
              <a href={dispatcherLink} target='_blank' rel='noreferrer'>
                <Button className='newInverted'>
                  Зв’язатись з диспетчером
                </Button>
              </a>
            </div>
          )}

          <div className={styles.btnContainer}>
            <Button className={styles.outlineBtn} onClick={closeHandler}>
              Закрити
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalSuccess
