import { useState, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'

import { REQ } from '@api/index'
import { useAppStore } from '@store/useAppStore'
import { ILoginFormTypes } from '@interfaces/global.type'
import { useDebounce } from '@hooks/useDebounce'
import { handleBackErrors } from '@utils/handleBackErrors'
import {
  getValidationErrorMessage,
  isValidationErrorsEmpty,
} from '@utils/validation'
import { loginFormDto } from './interfaces'
import { makeValidation } from './formValidations'

import SmallHeader from '@components/UI/SmallHeader/SmallHeader'
import Input from '@components/UI/Input/Input'
import Button from '@components/UI/Button/Button'

import styles from './form.module.sass'
import { ACCESS_TOKEN, REFRESH_TOKEN, USER_INFO } from 'const/token'

interface IProps {
  setFormType: (formType: ILoginFormTypes) => void
}

const LoginForm = ({ setFormType }: IProps) => {
  const { t } = useTranslation('login')
  const { push } = useRouter()
  const { rtkStatus } = useAppStore()

  const { login } = REQ.auth_api

  const [formValues, setFormValues] = useState<loginFormDto>({
    email: '',
    password: '',
  })
  const [validationErrors, setSetValidationErrors] = useState({})

  const debauncedValue = useDebounce(formValues, 1000)

  const { email, password } = formValues

  const submitLoginHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    login(formValues)
      .then((res) => {
        localStorage.setItem(ACCESS_TOKEN, res.data.access_token)
        localStorage.setItem(REFRESH_TOKEN, res.data.refresh_token)
        localStorage.setItem(USER_INFO, JSON.stringify(res.data.userInfo))
        push('/cabinet')
        toast.success(t('youAreLoggedIn'))
      })
      .catch((err) => {
        console.log(err)
        handleBackErrors(err)
      })
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  const formValidation = (formValues: any) => {
    const result = makeValidation(formValues, 'loginForm')

    if (result.status === 'error') {
      setSetValidationErrors(result.errors)
    } else {
      setSetValidationErrors({})
    }
  }

  useEffect(() => {
    formValidation(formValues)
  }, [debauncedValue])

  //if user came from recovery password page
  useEffect(() => {
    if (rtkStatus === 'rtkStatusSuccess' || rtkStatus === 'rtkStatusFailed') {
      setFormType('forgot')
    }
  }, [rtkStatus])

  return (
    <div className={styles.container}>
      <form onSubmit={submitLoginHandler}>
        <SmallHeader>{t('loginSubHeader')}</SmallHeader>
        <Input
          required
          placeholder={t('emailPlaceholder')}
          value={email}
          onChange={handleChangeInput}
          name='email'
          type='email'
          isValid={!validationErrors.hasOwnProperty('email')}
          errorMessage={getValidationErrorMessage(validationErrors, 'email')}
        />

        <Input
          required
          placeholder={t('passwordPlaceholder')}
          value={password}
          onChange={handleChangeInput}
          name='password'
          type='password'
          isValid={!validationErrors.hasOwnProperty('password')}
          errorMessage={getValidationErrorMessage(validationErrors, 'password')}
        />
        <Button
          type='submit'
          className='newInverted'
          disabled={
            email !== '' &&
            password !== '' &&
            !isValidationErrorsEmpty(validationErrors)
          }
        >
          {t('loginBtn')}
        </Button>

        <div
          className={styles.goToRestore}
          onClick={() => setFormType('forgot')}
        >
          <span>{t('forgotPw')}</span>
        </div>
      </form>
    </div>
  )
}

export default LoginForm
