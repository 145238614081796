export const availableCountries = [
  'ua',
  'cz',
  'pl',
  'sk',
  'it',
  'hu',
  'fr',
  'nl',
  'de',
  'at',
]
